import { Form, Modal, DatePicker, Button, Input } from "antd";
import React, { useState } from "react";
import Inputselect from "../../../components/inputselect/inputselect";
import Inputdate from "../../../components/inputdate/inputdate";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { openNotificationWithIcon } from "../../../utils/helper";

const { RangePicker } = DatePicker;

export interface TransactionsfilterInterface {
  visible: boolean;
  closeModal: () => void;
  setOtherFilters: React.Dispatch<any>;
  isLoan?: boolean;
  isCards?: boolean;
}

const Transactionsfilter = ({
  visible,
  closeModal,
  setOtherFilters,
  isLoan,
  isCards,
}: TransactionsfilterInterface) => {
  const [form] = Form.useForm();

  const [errors, setErrors] = useState({
    bank: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = (values: any) => {
    const { date_filter_from, date_filter_to } = values;

    let continueFilter = true;

    if (date_filter_from !== undefined && date_filter_to !== undefined) {
      if (moment(date_filter_from).isAfter(moment(date_filter_to))) {
        openNotificationWithIcon(
          "warning",
          "Filter Transaction",
          "Invalid date filter"
        );
        continueFilter = false;
      }
    }

    if (continueFilter) {
      setOtherFilters(values);
      closeModal();
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current > moment().endOf("day");
  };

  return (
    <div>
      <Modal
        onCancel={closeModal}
        onOk={closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-xl font-semibold font-ibmplexsans text-appcolorblue">
              Apply Filter
            </h1>
          </div>

          <div className="mt-10">
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Form.Item label="Date From" name="date_filter_from">
                <Inputdate
                  updateField={updateField}
                  name="date_filter_from"
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item label="Date To" name="date_filter_to">
                <Inputdate
                  updateField={updateField}
                  name="date_filter_to"
                  disabledDate={disabledDate}
                />
              </Form.Item>

              {!isCards && (
                <Form.Item label="Status" name="status">
                  <Inputselect
                    placeHolder="Select Status"
                    updateField={updateField}
                    name={"status"}
                    selectOptions={
                      isLoan
                        ? [
                            "Failed",
                            "Successful",
                            "Disbursed",
                            "Processing",
                            "Approved",
                            "Overdue",
                            "Repaid",
                          ]
                        : ["Successful", "Pending", "Cancelled"]
                    }
                    optionHasValue={true}
                  />
                </Form.Item>
              )}

              {!isLoan && !isCards && (
                <Form.Item label="Transaction Type" name="type">
                  <Inputselect
                    placeHolder="Select Transaction Type"
                    updateField={updateField}
                    name={"type"}
                    selectOptions={["Credit", "Debit"]}
                    optionHasValue={true}
                  />
                </Form.Item>
              )}

              <Form.Item label="Reference Code" name="reference_code">
                <Input placeholder="Reference Code" />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="mb-2">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Transactionsfilter;
