import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  HomeIcon,
  Bars3Icon,
  ChevronRightIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  DocumentDuplicateIcon,
  UserMinusIcon,
  ChartBarSquareIcon,
  UserCircleIcon,
  WalletIcon,
  ClipboardDocumentIcon,
  BriefcaseIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import * as SolidIcons from "@heroicons/react/24/solid";
import { UserIcon } from "@heroicons/react/24/solid";
import { Button, Modal, Tag, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { logoutUser } from "../../store/userSlice/userSlice";
import {
  transformVerificationKey,
  transformVerificationRoute,
} from "../../utils/helper";
import { RightOutlined } from "@ant-design/icons";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

const Dashboardpage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [originalKyc, setOriginalKyc] = useState({});
  // const [kycTotallyDone, setKycTotallyDone] = useState(false);
  const [showAllKyc, setShowAllKyc] = useState(false);
  const [visible, setVisible] = useState(false);
  const [kycDone, setKycDone] = useState(false);
  const [otpDone, setOtpDone] = useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const desktopTopRoutes = [
    {
      title: "Home",
      text: "",
      icon: HomeIcon,
      link: "/dashboard",
    },
    // {
    //   title: "Business",
    //   text: "",
    //   icon: BriefcaseIcon,
    //   link: "/dashboard/business-tools",
    // },
    // {
    //   title: "Cards",
    //   text: "",
    //   icon: CreditCardIcon,
    //   link: "/dashboard/cards",
    // },
    {
      title: "My Profile",
      text: "",
      icon: UserCircleIcon,
      link: "/dashboard/accounts",
    },
    {
      title: "Account Status",
      text: "",
      icon: NewspaperIcon,
      link: "/dashboard/accounts/account-status",
    },
    {
      title: "My Wallet Accounts",
      text: "",
      icon: WalletIcon,
      link: "/dashboard/virtual-accounts",
    },
    {
      title: "Transactions",
      text: "",
      icon: DocumentChartBarIcon,
      link: "/dashboard/transactions",
    },
    // {
    //   title: "Request Statement",
    //   text: "Your loan Statement",
    //   icon: ChartBarSquareIcon,
    //   link: "/dashboard/loan-statement",
    // },
    // {
    //   title: 'Credit Report',
    //   text: 'View your credit report',
    //   icon: HomeIcon,
    //   link: '/dashboard'
    // },
    {
      title: "Link Cards",
      text: "",
      icon: CreditCardIcon,
      link: "/dashboard/linked-cards",
    },
  ];

  const topRoutes = [
    {
      title: "Home",
      text: "Verified users",
      icon: HomeIcon,
      link: "/dashboard",
    },
    {
      title: "My Profile",
      text: "View accounts",
      icon: UserCircleIcon,
      link: "/dashboard/accounts",
    },
    {
      title: "Account Status",
      text: "",
      icon: NewspaperIcon,
      link: "/dashboard/accounts/account-status",
    },
    {
      title: "My Wallet Accounts",
      text: "Manage your virtual accounts",
      icon: WalletIcon,
      link: "/dashboard/virtual-accounts",
    },
    {
      title: "Transactions",
      text: "View details of transactions",
      icon: DocumentChartBarIcon,
      link: "/dashboard/transactions",
    },
    // {
    //   title: "Request Statement",
    //   text: "Your loan Statement",
    //   icon: ChartBarSquareIcon,
    //   link: "/dashboard/loan-statement",
    // },
    // {
    //   title: 'Credit Report',
    //   text: 'View your credit report',
    //   icon: HomeIcon,
    //   link: '/dashboard'
    // },
    {
      title: "Link Cards",
      text: "Set up card",
      icon: CreditCardIcon,
      link: "/dashboard/linked-cards",
    },
  ];

  const bottomRoutes = [
    // {
    //   title: "Documents",
    //   text: "View all your documents",
    //   icon: DocumentDuplicateIcon,
    //   link: "/dashboard/documents",
    // },
    {
      title: "Support",
      text: "Chat with support team",
      icon: QuestionMarkCircleIcon,
      link: "/dashboard/support",
    },
    {
      title: "Manage account",
      text: "Protect your account ",
      icon: ShieldCheckIcon,
      link: "/dashboard/manage-account",
    },
  ];

  useEffect(() => {
    if (user !== null) {
      let kyc = user.kyc;
      setKycDone(kyc?.remaining_track === 0 ? true : false);
      // let emailDone = false;
      // let phoneDone = false;

      // for (let index = 0; index < kyc.length; index++) {
      //   let element = kyc[index];
      //   Object.entries(element).forEach(([key, value]) => {
      //     if (key === "identity_document") {
      //       if (value === "Pending") {
      //         setKycDone(false);
      //       } else {
      //         setKycDone(true);
      //       }
      //     }

      //     if (key === "email") {
      //       if (value === "Pending") {
      //         emailDone = false;
      //       } else {
      //         emailDone = true;
      //       }
      //     }

      //     if (key === "phone") {
      //       if (value === "Pending") {
      //         phoneDone = false;
      //       } else {
      //         phoneDone = true;
      //       }
      //     }
      //   });
      // }

      // setOtpDone(emailDone && phoneDone);
    }
  }, [user]);

  // useEffect(() => {
  //   if (user !== null) {
  //     let kyc = user.kyc;
  //     const toCheck: any = {};

  //     for (let index = 0; index < kyc.length; index++) {
  //       let element = kyc[index];
  //       Object.entries(element).forEach(([key, value]) => {
  //         toCheck[`${key}`] = value;
  //       });
  //     }

  //     setOriginalKyc(toCheck);

  //     if (Object.values(toCheck).every((item) => item === "Successful")) {
  //       setKycTotallyDone(true);
  //     } else {
  //       setKycTotallyDone(false);
  //     }
  //   }
  // }, []);

  return (
    <div className="relative">
      <div className="fixed top-0 left-0 z-30 flex items-center justify-between w-full p-4 bg-appcolorwhite">
        <div className="flex items-center justify-between lg:hidden">
          <div className="mt-[0rem] profile-img-holder">
            {user?.photo ? (
              <>
                <img
                  src={user?.photo}
                  alt=""
                  className="object-cover rounded-full w-11 h-11"
                />
              </>
            ) : (
              <>
                <div className="p-2 rounded-full bg-appcolorgray w-11 h-11">
                  <UserIcon />
                </div>
              </>
            )}
          </div>
          <div className="pl-4">
            <span className="font-semibold">Account ID</span>
            <CopyToClipboard
              text={user?.user_id}
              onCopy={() => {
                message.info("Account ID copied to clipboard");
              }}
            >
              <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
                <span className="pr-8">{user?.user_id}</span>
                <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
              </div>
            </CopyToClipboard>
          </div>
          {/* <div className="pl-4">
            <span className="block text-xs font-semibold b-0 font-poppins">
              Hi {user?.firstname}
            </span>
            <span className="text-xs b-0 font-poppins text-appcolorgrey">
              Welcome
            </span>
          </div> */}
        </div>

        {location.pathname === "/dashboard/confirm-otp" ? null : (
          <>
            <div className="flex items-center justify-center">
              {/* {!kycTotallyDone ? (
            <Button
              className="ml-4 mr-4 w-fit yellow-btn"
              onClick={() => {
                setShowAllKyc(true);
              }}
            >
              Verify Account
            </Button>
          ) : null} */}

              <div className="ml-auto rounded-full mp-2 justify-self-end bg-appcolorwhite w-11 h-11 lg:hidden">
                <button
                  className="w-full"
                  onClick={() => {
                    toggleVisible();
                  }}
                >
                  <Bars3Icon className="cursor-pointer" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="px-4 mt-24 lg:mt-[2rem] lg:ml-[20rem]">
        <div className="flex">
          {!kycDone &&
            !location.pathname.includes("/dashboard/accounts") &&
            !location.pathname.includes("/dashboard/confirm-otp") && (
              <Button
                className="mb-2"
                onClick={() => {
                  // navigate('/dashboard/continue-kyc')
                  navigate("/dashboard/accounts/account-status");
                }}
              >
                Complete KYC
              </Button>
            )}

          {/* {
						!otpDone &&
						<Button className='mb-2'
							onClick={() => {
								navigate('/dashboard/confirm-otp')
							}}
						>
							Confirm OTP
						</Button>
					} */}
        </div>

        <Outlet />
      </div>

      {location.pathname === "/dashboard/confirm-otp" ? null : (
        <div className="fixed bottom-0 left-0 flex items-start justify-between w-full p-6 drop-shadow-2xl bg-appcolorwhite lg:hidden md:hidden">
          <div
            className="flex flex-col items-center"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <SolidIcons.HomeIcon
              className={`h-6 w-6 ${
                location.pathname === "/dashboard"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            />
            <span
              className={`text-sm font-poppins ${
                location.pathname === "/dashboard"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            >
              Home
            </span>
          </div>
          <div
            className="flex flex-col items-center"
            onClick={() => {
              navigate("/dashboard/wallets");
            }}
          >
            <SolidIcons.WalletIcon
              className={`h-6 w-6 ${
                location.pathname === "/dashboard/wallets"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            />
            <span
              className={`text-sm font-poppins ${
                location.pathname === "/dashboard/wallets"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            >
              Wallets
            </span>
          </div>
          {/* <div
            className="flex flex-col items-center"
            onClick={() => {
              navigate("/dashboard/business-tools");
            }}
          >
            <SolidIcons.BriefcaseIcon
              className={`h-6 w-6 ${
                location.pathname === "/dashboard/business-tools"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            />
            <span
              className={`text-sm font-poppins ${
                location.pathname === "/dashboard/business-tools"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            >
              Business
            </span>
          </div> */}
          {/* <div
            className="flex flex-col items-center"
            onClick={() => {
              navigate("/dashboard/cards");
            }}
          >
            <SolidIcons.CreditCardIcon
              className={`h-6 w-6 ${
                location.pathname === "/dashboard/cards"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            />
            <span
              className={`text-sm font-poppins ${
                location.pathname === "/dashboard/cards"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            >
              Cards
            </span>
          </div> */}
          <div
            className="flex flex-col items-center"
            onClick={() => {
              navigate("/dashboard/accounts");
            }}
          >
            <SolidIcons.UserCircleIcon
              className={`h-6 w-6 ${
                location.pathname === "/dashboard/accounts"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            />
            <span
              className={`text-sm font-poppins ${
                location.pathname === "/dashboard/accounts"
                  ? "text-appcolorblue"
                  : "text-appcolorgray"
              }`}
            >
              Profile
            </span>
          </div>
        </div>
      )}

      {visible && (
        <div
          className="fixed top-0 z-40 w-full h-full bg-appcoloroverlayblack lg:hidden"
          onClick={() => {
            toggleVisible();
          }}
        ></div>
      )}

      {/* desktop nav */}
      <div className="fixed top-0 z-50 hidden h-screen p-6 pt-8 overflow-y-auto duration-500 bg-appcolorwhite w-[19rem] drop-shadow lg:block">
        <div className="flex mb-8">
          <div className="mt-[0rem]">
            {user?.photo ? (
              <>
                <div className="mr-4 rounded-full h-11 w-11">
                  <img
                    src={user?.photo}
                    alt=""
                    className="object-cover w-full h-full rounded-full"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="p-2 rounded-full bg-appcolorgray w-11 h-11">
                  <UserIcon />
                </div>
              </>
            )}
          </div>

          <div className="flex justify-between w-full ml-3">
            <div>
              <p>{user?.firstname}</p>
              <p>{user?.lastname}</p>

              {/* <span className='underline text-appcolordeepwhite'>
                Personal Details
              </span> */}
            </div>

            <div
              className="hidden cursor-pointer w-9 h-9"
              onClick={() => {
                toggleVisible();
              }}
            >
              <ChevronRightIcon />
            </div>
          </div>
        </div>

        <div className="flex items-center mb-4">
          <span className="pr-4 font-semibold">Account ID: </span>
          <CopyToClipboard
            text={user?.user_id}
            onCopy={() => {
              message.info("Account ID copied to clipboard");
            }}
          >
            <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
              <span className="pr-8">{user?.user_id}</span>
              <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
            </div>
          </CopyToClipboard>
        </div>

        <div className="overflow-y-auto">
          {desktopTopRoutes.map((item, index) => (
            <div
              className="flex items-center mb-4 cursor-pointer"
              onClick={() => {
                if (item.link !== undefined) {
                  navigate(item.link);
                }
                toggleVisible();
              }}
              key={`deskyop-toproues-two-${index}`}
            >
              <div className="w-6 h-6 mt-2 mr-8">
                {React.createElement(item.icon, {
                  className: "text-appcolorblue",
                })}
              </div>
              <div className="mt-[0.5rem]">
                <p className="font-bold">{item.title}</p>
                {/* <p className="text-xs text-appcolorlightharsh">{item.text}</p> */}
              </div>
            </div>
          ))}

          <hr className="h-px my-4 mb-0 bg-gray-500 border-0" />

          {bottomRoutes.map((item) => (
            <div
              className="flex items-center mb-4 cursor-pointer"
              onClick={() => {
                if (item.link !== undefined) {
                  navigate(item.link);
                }
                toggleVisible();
              }}
            >
              <div className="w-6 h-6 mt-2 mr-8">
                {React.createElement(item.icon, {
                  className: "text-appcolorblue",
                })}
              </div>
              <div className="mt-[1rem]">
                <p className="font-bold">{item.title}</p>
                <p className="text-xs text-appcolorlightharsh">{item.text}</p>
              </div>
            </div>
          ))}

          <div
            className="flex items-center mb-4 cursor-pointer"
            onClick={() => {
              navigate("/signin");
              dispatch(logoutUser());
            }}
          >
            <div className="w-6 h-6 mr-8">
              <UserMinusIcon className="text-appcolorblue" />
            </div>
            <div className="">
              <p className="font-bold">Logout</p>
            </div>
          </div>
        </div>
      </div>

      {/* normal mobile one */}
      <div
        className="fixed top-0 z-50 h-screen p-6 pt-8 overflow-y-auto duration-500 bg-appcolorwhite w-80 drop-shadow-2xl lg:hidden"
        style={
          !visible
            ? {
                left: "-350px",
                opacity: 0,
              }
            : {
                left: 0,
                opacity: 1,
              }
        }
      >
        <div className="flex mb-8">
          <div className="mt-[0rem]">
            {user?.photo ? (
              <>
                <div className="mr-4 rounded-full h-11 w-11">
                  <img
                    src={user?.photo}
                    alt=""
                    className="object-cover w-full h-full rounded-full"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="p-2 rounded-full bg-appcolorgray w-11 h-11">
                  <UserIcon />
                </div>
              </>
            )}
          </div>

          <div className="flex justify-between w-full ml-3">
            <div>
              <p>{user?.firstname}</p>
              <p>{user?.lastname}</p>

              {/* <span className='underline text-appcolordeepwhite'>
                Personal Details
              </span> */}
            </div>

            <div
              className="cursor-pointer w-9 h-9"
              onClick={() => {
                toggleVisible();
              }}
            >
              <ChevronRightIcon />
            </div>
          </div>
        </div>

        <div className="overflow-y-auto">
          {topRoutes.map((item, index) => (
            <div
              className="flex items-center mb-4 cursor-pointer"
              onClick={() => {
                if (item.link !== undefined) {
                  navigate(item.link);
                }
                toggleVisible();
              }}
              key={`toproues-two-${index}`}
            >
              <div className="w-6 h-6 mt-2 mr-8">
                {React.createElement(item.icon, {
                  className: "text-appcolorblue",
                })}
              </div>
              <div className="mt-[0.5rem]">
                <p className="font-bold">{item.title}</p>
                {/* <p className="text-xs text-appcolorlightharsh">{item.text}</p> */}
              </div>
            </div>
          ))}

          <hr className="h-px my-4 mb-0 bg-gray-500 border-0" />

          {bottomRoutes.map((item) => (
            <div
              className="flex items-center mb-4 cursor-pointer"
              onClick={() => {
                if (item.link !== undefined) {
                  navigate(item.link);
                }
                toggleVisible();
              }}
            >
              <div className="w-6 h-6 mt-2 mr-8">
                {React.createElement(item.icon, {
                  className: "text-appcolorblue",
                })}
              </div>
              <div className="mt-[1rem]">
                <p className="font-bold">{item.title}</p>
                <p className="text-xs text-appcolorlightharsh">{item.text}</p>
              </div>
            </div>
          ))}

          <div
            className="flex items-center mb-4 cursor-pointer"
            onClick={() => {
              navigate("/signin");
              dispatch(logoutUser());
            }}
          >
            <div className="w-6 h-6 mr-8">
              <UserMinusIcon className="text-appcolorblue" />
            </div>
            <div className="">
              <p className="font-bold">Logout</p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={showAllKyc}
        footer={null}
        onCancel={() => {
          setShowAllKyc(false);
        }}
        onOk={() => {
          setShowAllKyc(false);
        }}
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Verification Tracks
            </h1>
          </div>

          <p className="mb-4 text-sm font-normal font-poppins text-appcolorgrey">
            Here is a list of all your verification tracks.
          </p>

          {Object.entries(originalKyc).map(([key, value]) => (
            <div
              className={`flex items-center mb-4 ${
                value === "Pending" ? "cursor-pointer" : ""
              }`}
              onClick={() => {
                if (value === "Pending") {
                  let routing = transformVerificationRoute(key);
                  if (routing !== "") {
                    navigate(`${routing}`);
                    setShowAllKyc(false);
                  }
                }
              }}
            >
              <div className="flex justify-between mb-4 ml-2 grow">
                <div className="flex items-start">
                  <h2 className="mr-3 text-base font-semibold font-ibmplexsans text-appcolorblack">
                    {transformVerificationKey(key)}
                  </h2>

                  {value === "Pending" ? (
                    <Tag color="processing">Pending</Tag>
                  ) : (
                    <Tag color="success">Successful</Tag>
                  )}
                </div>

                {value === "Pending" ? <RightOutlined /> : null}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Dashboardpage;
