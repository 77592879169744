import React from "react";
import { useNavigate } from "react-router-dom";

const Manageaccount = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-4 mb-8">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>
      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Manage Account
      </h1>
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            navigate("/dashboard/change-password");
          }}
        >
          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Change PIN
            </h1>
            <p className="text-sm text-appcolorlightharsh">
              Change or update your pin.
            </p>
          </div>
        </div>

        <div
          className="flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            navigate("/dashboard/update-email");
          }}
        >
          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Update Email
            </h1>
            <p className="text-sm text-appcolorlightharsh">Update your email</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manageaccount;
