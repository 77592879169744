import React from "react";
import { Select } from "antd";
import { inputSelectInterface } from "./inputselect-interface";
import { nanoid } from "nanoid";

const { Option } = Select;

const Inputselect = ({
  hasError,
  placeHolder,
  selectOptions,
  updateField,
  name,
  showSearch,
  optionHasValue,
  loading,
  selectOptionLabel,
  selectValue,
  alternativeValue,
  isMultiple,
  icon,
  disabled,
}: inputSelectInterface) => {
  return (
    <div className="flex w-full p-2 px-4 rounded-lg bg-appcolorshade input-select">
      {icon !== undefined && icon}
      <Select
        optionFilterProp={showSearch ? "chilren" : undefined}
        mode={isMultiple ? "multiple" : undefined}
        bordered={false}
        showSearch={showSearch}
        loading={loading}
        value={selectValue}
        placeholder={placeHolder}
        disabled={disabled}
        onChange={(value) => {
          if (updateField !== undefined) {
            updateField(name, value);
          }
        }}
        filterOption={
          showSearch
            ? (input, option: any): any => {
                if (option.options !== undefined) {
                  option.options.forEach((item: any) => {
                    return item.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  });
                } else {
                  return (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }
              }
            : undefined
        }
      >
        {selectOptions?.map((item: any, index: number) => (
          <Option
            value={
              optionHasValue
                ? item
                : alternativeValue
                ? item[alternativeValue]
                : item.id
            }
            key={`${name}-${index}`}
          >
            {selectOptionLabel !== undefined ? item[selectOptionLabel] : item}
          </Option>
        ))}
      </Select>
    </div>
  );
};

Inputselect.defaultProps = {
  loading: false,
  showSearch: false,
  optionHasValue: false,
  disabled: false,
  selectOptions: [],
  isMultiple: false,
};

export default Inputselect;
