import { DocumentIcon } from "@heroicons/react/24/outline";
import React from "react";

const Inhousecard = () => {
  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto  lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Cards
          </h1>
        </div>

        <div className="pt-4 pm-16">
          <div className="flex flex-col items-center justify-center mt-2">
            <div className="w-11 h-11">
              <DocumentIcon className="cursor-pointer" />
            </div>
            <p className="text-center cursor-pointer font-poppins">
              {" "}
              Cards service coming soon
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inhousecard;
