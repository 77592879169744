import { Alert, Button, Form, Radio, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
} from "../../../utils/helper";
import { fundAccount, getWalletsDetails } from "../../../api/wallet/wallets";
import Inputselect from "../../../components/inputselect/inputselect";
import Inputamount from "../../../components/inputamount/inputamount";
import Addmoneymodal from "./addmoneymodal";
import { getCards } from "../../../api/card/card";

const paymentMethodForNGN = [
  {
    id: "USSD",
    name: "USSD",
  },
  {
    id: "CARD",
    name: "CARD",
  },
];

const paymentMethodForOthers = [
  {
    id: "CARD",
    name: "CARD",
  },
];

const Addmoney = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fundingOptions = ["WALLET ACCOUNT"];

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentLink, setPaymentLink] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    wallet_id: "",
    // option: "",
    amount: "",
  });
  const [error, setError] = useState(null);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [walletCurrency, setWalletCurrency] = useState(null);
  const [loadingCards, setLoadingCards] = useState(false);
  const [cards, setCards] = useState<any[]>([]);

  const closeShowPaymentModal = () => {
    setShowPaymentModal(false);
  };

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user, reloadWallet } = state;

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "wallet_id") {
      const getCurrentWallet = wallets.filter(
        (item) => item.wallet_id === value
      );
      if (getCurrentWallet.length > 0) {
        setWalletCurrency(getCurrentWallet[0].currency);
      }
    }
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();

    // eslint-disable-next-line
  }, []);

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await fundAccount({
          ...values,
          // option: "CARD",
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Add Money", message);

          setPaymentLink(data);
          setShowPaymentModal(true);
          // form.resetFields();

          // getWallet();
        } else {
          setError(message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong: Add Money");
      }
    } else {
      setLoading(true);
    }
  };

  const fetchCards = async () => {
    setLoadingCards(true);

    try {
      const res = await getCards({
        currency: "ngn",
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null && data !== undefined) {
          let newCards: any[] = [];
          data.forEach((item: any) => {
            newCards.push({
              ...item,
              cardLabel: `**** **** **** ${item.last4} - ${item.month} / ${item.year}`,
            });
          });
          setCards(newCards);
          // setTotal(parseInt(res.data.counts));
        } else {
          setCards([]);
        }
      } else {
        message.warning(`Get cards, ${res.data.message}`);
      }

      setLoadingCards(false);
    } catch (error) {
      setLoadingCards(false);
      message.error("Something went wrong: Get cards");
    }
  };

  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const targetElement = document.querySelector(".ant-modal-mask");

    // Add the class when the component is mounted
    if (targetElement) {
      targetElement.classList.add("payment-link-modal-mask");
    }

    // Remove the class when the component is about to unmount
    return () => {
      if (targetElement) {
        targetElement.classList.remove("payment-link-modal-mask");
      }
    };
  }, []);

  return (
    <div className="mt-4">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Add Money
          </h1>
        </div>

        <div>
          <div className="mb-4">
            <Alert
              message={
                <span className="font-poppins">
                  <b>NOTE</b>: Adding money using this features are not
                  instantly settled. Try using the Wallet Accounts
                </span>
              }
              type="info"
              className="w-full"
            />
          </div>

          {error != null && (
            <div className="flex justify-center mb-4">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form
            className="w-full"
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <Form.Item
              validateStatus={
                errors?.wallet_id.length > 0 ? "error" : undefined
              }
              help={errors?.wallet_id.length > 0 ? errors.wallet_id : undefined}
              label="Wallet"
              name="wallet_id"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={updateField}
                name={"wallet_id"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="wallet_id"
              />
            </Form.Item>

            <Form.Item
              validateStatus={errors?.amount.length > 0 ? "error" : undefined}
              help={errors?.amount.length > 0 ? errors.amount : undefined}
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Amount is required",
                },
              ]}
            >
              <Inputamount
                name="amount"
                updateField={updateField}
                placeHolder="Enter amount"
                currency={transformCurrencyToSymbol(walletCurrency || "")}
                amountValue={form.getFieldValue("amount")}
                disabled={form.getFieldValue("wallet_id") === undefined}
              />
            </Form.Item>

            {/* {form.getFieldValue("wallet_id") ? (
              <Form.Item
                validateStatus={errors?.option.length > 0 ? "error" : undefined}
                help={errors?.option.length > 0 ? errors.option : undefined}
                label="Payment Method"
                name="option"
                rules={[
                  {
                    required: true,
                    message: "Payment Method is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Payment Method"
                  updateField={updateField}
                  name={"option"}
                  selectOptions={
                    walletCurrency === null
                      ? []
                      : walletCurrency === "ngn"
                      ? paymentMethodForNGN
                      : paymentMethodForOthers
                  }
                  loading={loadingWallets}
                  selectOptionLabel="name"
                  alternativeValue="id"
                />
              </Form.Item>
            ) : null} */}

            {form.getFieldValue("wallet_id") ? (
              <Form.Item
                // validateStatus={errors?.option.length > 0 ? "error" : undefined}
                // help={errors?.option.length > 0 ? errors.option : undefined}
                label="Payment Card"
                name="card_reference_code"
                // rules={[
                //   {
                //     required: true,
                //     message: "Payment Method is required",
                //   },
                // ]}
              >
                <Inputselect
                  placeHolder="Select Payment Card"
                  updateField={updateField}
                  name={"card_reference_code"}
                  selectOptions={cards}
                  loading={loadingCards}
                  selectOptionLabel="cardLabel"
                  alternativeValue="reference_code"
                  showSearch={true}
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                Proceed
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {showPaymentModal && (
        <Addmoneymodal
          visible={showPaymentModal}
          closeModal={closeShowPaymentModal}
          paymentLink={paymentLink}
        />
      )}
    </div>
  );
};

export default Addmoney;
