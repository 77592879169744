import { Modal } from "antd";
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

export interface AddmoneymodalInterface {
  visible: boolean;
  closeModal: () => void;
  paymentLink: any;
}

const Addmoneymodal = ({
  visible,
  closeModal,
  paymentLink,
}: AddmoneymodalInterface) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <Modal
        onCancel={submitting ? undefined : closeModal}
        onOk={submitting ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
        width={"100%"}
        className="payment-link-modal"
        closable={true}
        style={{
          top: 0,
        }}
        closeIcon={
          <CloseOutlined
						onClick={() => {
							closeModal()
						}}
            style={{
              color: "red",
              fontSize: 20,
            }}
          />
        }
      >
        <div>
          <iframe
            style={{
              height: "100vh",
            }}
            className="w-full "
            src={paymentLink?.payment_link}
            //@ts-ignore
            frameborder="0"
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};

export default Addmoneymodal;
