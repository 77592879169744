import React, { useState, useRef, useEffect } from "react";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import Okra from "okra-js";
import { openNotificationWithIcon } from "../../../utils/helper";
import { runIdentityKYC } from "../../../api/identity/identity";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import axios from "axios";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { uploadSelfie } from "../../../api/base/base";
import { logoutUser } from "../../../store/userSlice/userSlice";

const Continuekyc = () => {
  const navigate = useNavigate();
  let videoRef = useRef(null);
  let photoRef = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [imgeDataURL, setImgeDataURL] = useState(null);
  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        let video: any = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const takePicture = () => {
    const width = 400;
    const height = width / (12 / 9);

    let video = videoRef.current;

    let photo: any = photoRef.current;

    photo.width = width;

    photo.height = height;

    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, width, height);

    var image = photo.toDataURL("image/jpeg");
    setImgeDataURL(image);
  };

  const clearImage = () => {
    let photo: any = photoRef.current;

    let ctx = photo.getContext("2d");

    ctx.clearRect(0, 0, photo.width, photo.height);
  };

  useEffect(() => {
    getVideo();
  }, [videoRef]);

  const submitPhoto = async () => {
    setLoading(true);
    try {
      const canvas: any = photoRef.current;

      canvas.toBlob(async (blob: any) => {
        const formData = new FormData();
        formData.append("file", blob, "selfie.png");
        const res = await axios.post(
          `${REACT_APP_BASE_URL}/misc/file-upload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { status, data } = res.data;

        if (status === "success") {
          const resUpload = await uploadSelfie({
            file_url: data,
          });

          setLoading(false);

          if (resUpload.data.status === "success") {
            openNotificationWithIcon(
              "success",
              "Upload Indentification",
              resUpload.data.message
            );
            navigate("/dashboard/kyc-success");
            // dispatch(logoutUser())
          } else {
            message.warning(
              `Upload Indentification, ${resUpload.data.message}`
            );
          }
        } else {
          message.warning(`Upload Indentification, ${res.data.message}`);
        }
      });
    } catch (error: any) {
      setLoading(false);
      message.error("Something went wrong: Upload Indentification");
    }
  };

  const validateKYC = async () => {
    setLoading(true);
    try {
      const res = await runIdentityKYC();

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Validate KYC", message);

        if (data?.status === "Initiate") {
          widgetOkra();
        }

        if (data?.status === "Successful") {
          navigate("/dashboard/kyc-success");
        }

        if (data?.status === "Processing") {
        }
      } else {
        openNotificationWithIcon("error", "Validate KYC", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Create wallets");
    }
  };

  const widgetOkra = () => {
    Okra.buildWithOptions({
      selfieVerify: true,
      email: user?.email,
      color: "#f1c34c",
      name: "Kiowope Capital Limited",
      env: "production",
      app_id: "6396225f16f944b92c601e4e", // app_id from your app builder
      key: "1e6b5367-4335-51bc-bdb0-c09237a63be6",
      token: "6384c143838efd0d6c087840",
      products: ["auth", "identity", "balance", "transactions", "income"],
      onSuccess: (data: any) => {
        // console.log('options success', data)
        // validateKYC()
      },
      onClose: () => {
        // openNotificationWithIcon("error", "Run KYC", "Something went wrong!")
      },
    });
  };

  return (
    <div className="flex flex-col pb-16">
      <div className="flex justify-center">
        <img
          src="/img/vectors/continue.png"
          alt="continue"
          className="my-12 w-18"
        />
      </div>

      <div>
        <h1 className="mb-2 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
          Let’s Continue!
        </h1>

        <p className="w-11/12 mx-auto mb-2 text-sm font-normal text-left font-poppins text-appcolorgrey mb:text-center lg:text-center">
          Simply use your device’s camera to capture the
          <br />
          following:
        </p>

        <ol className="w-9/12 mx-auto mb-4  lg:w-3/12">
          {/* <li className=''>
            <span className='text-sm font-normal text-appcolorgrey font-poppins '>
              1. Your identification document
            </span>
          </li> */}
          <li className="font-normal font-poppins">
            <span className="text-sm font-normal text-appcolorgrey font-poppins ">
              1. A Quick Selfie
            </span>
          </li>
        </ol>

        <div className="w-11/12 mx-auto mt-3 mb-4  lg:w-[25%]">
          <video ref={videoRef} className="container"></video>

          <div className="flex mt-2">
            <Button
              className="mb-2"
              onClick={() => {
                takePicture();
              }}
              // loading={loading}
              disabled={loading}
            >
              Take Photo
            </Button>

            <Button
              className="mb-2"
              onClick={() => {
                clearImage();
              }}
              // loading={loading}
              disabled={loading}
            >
              Clear Photo
            </Button>
          </div>

          <p className="text-xs text-center">The image will appear below</p>

          <canvas className="container" ref={photoRef}></canvas>
        </div>

        {imgeDataURL !== null && (
          <div className="flex flex-col items-center justify-center w-11/12 mx-auto mt-3 mb-4  lg:w-3/12">
            <Button
              className="mb-2"
              onClick={() => {
                submitPhoto();
              }}
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        )}

        <p className="mb-2 font-semibold text-center text-normal font-ibmplexsans text-appcolorblue">
          <span
            className="cursor-pointer"
            onClick={() => {
              if (!loading) {
                navigate("/dashboard");
              }
            }}
          >
            Remind Me Later
          </span>
        </p>
      </div>
    </div>
  );
};

export default Continuekyc;
