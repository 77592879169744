import React, { useState } from "react";
import { Form, Input, Button, message, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { changePassword, updateEmail } from "../../../api/base/base";
import { openNotificationWithIcon } from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/userSlice/userSlice";

const Updateemail = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
  });

  const onFinish = async (values: any) => {
    const { email } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await updateEmail({
          email,
        });

        const { status } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Update Email", res.data.message);

          navigate("/signin");
        } else {
          setError(res.data.message);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error("Something went wrong: Update Email");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 mb-8">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Update Email
      </h1>

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            email: user?.email,
          }}
        >
          <Form.Item
            validateStatus={errors?.email.length > 0 ? "error" : undefined}
            help={errors?.email.length > 0 ? errors.email : undefined}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Invalid Email",
              },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto my-8 mb-4">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Updateemail;
