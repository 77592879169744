import React, { useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { Form, Input, Button, message, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import {
  openNotificationWithIcon,
  validatePhoneNumber,
} from "../../utils/helper";
import { formValuesInterface } from "./forgotpassword-interface";
import { retrieveAccount } from "../../api/base/base";
import Inputwithaddon from "../../components/inputwithaddon/inputwithaddon";

const Forgotpassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Email",
      active: false,
    },
    {
      id: nanoid(),
      name: "Phone",
      active: true,
    },
  ]);

  const [errors, setErrors] = useState({
    phone: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);

    form.setFieldsValue({
      email: undefined,
    });
    form.setFieldsValue({
      phone: undefined,
    });
  };

  const onFinish = async (values: formValuesInterface) => {
    const { email, phone } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (tabs[0].active) {
      newErrors["phone"] = "";
    } else {
      if (!validatePhoneNumber(`+234${phone}`)) {
        newErrors["phone"] = "Invalid phone number";
      } else {
        newErrors["phone"] = "";
      }
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const checkCurrentTab = tabs.filter((item) => item.active)[0];
        const currentTabName = checkCurrentTab.name.toLowerCase();

        const res = await retrieveAccount(
          {
            [currentTabName]: tabs[0].active ? email : `+234${phone}`,
          },
          currentTabName
        );

        const { status, message } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Retrieve Account", message);

          navigate("/signin");
        } else {
          setError(message);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error("Something went wrong: Sign up");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 ">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-30" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Retrieve Account
      </h1>

      <p className="text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey">
        Enter your Registered Phone Number.
      </p>

      {/* <div className="tab-holder grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-11/12  lg:w-3/12 mx-auto mb-6">
        {tabs.map((item, index) => (
          <div
            className={`tab-item font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
              item.active ? "bg-appcolorwhite rounded-xl" : "bg-transparent"
            }`}
            onClick={() => {
              switchTabs(index);
            }}
            key={item.id}
          >
            {item.name}
          </div>
        ))}
      </div> */}

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4  lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto  lg:w-3/12">
        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          {tabs[0].active ? (
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid Email",
                },
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          ) : (
            <Form.Item
              validateStatus={errors?.phone.length > 0 ? "error" : undefined}
              help={errors?.phone.length > 0 ? errors.phone : undefined}
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
              ]}
            >
              <Inputwithaddon
                updateField={updateField}
                name="phone"
                placeHolder="8000000000"
                addonBefore="+234"
              />
            </Form.Item>
          )}

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto my-8 ">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                PIN Reset
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <div className="relative w-11/12 mx-auto mb-4  lg:w-3/12">
        <hr className="relative border-2 border-appcolorfaint" />
        <p className="absolute bottom-0 left-0 right-0 z-10 px-2 m-auto text-sm font-normal text-center font-poppins text-appcolorgrey -top-2 bg-appcolorwhite">
          Already have an account?
        </p>
      </div>

      <div className="flex flex-col items-center justify-center w-11/12 mx-auto mb-20  lg:w-3/12">
        <Button
          className="purple-skel"
          onClick={() => {
            navigate("/signin");
          }}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default Forgotpassword;
